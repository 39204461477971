<template>
  <div>
    <entity-header
      class="mb-10"
      :title="service.name"
      :is-archived="isArchived"
      :back-route="backRoute"
      :edit-route="editRoute"
      :can-update="canUpdate"
      :loading="isLoading"
      @archive="archive"
    />

    <div v-if="isLoading" class="d-flex justify-center primary--text">
      <v-progress-circular indeterminate />
    </div>
    <view-builder v-else :schema="$options.schema" :data="service" />
  </div>
</template>
<script>
import EntityHeader from '@/components/EntityHeader.vue';
import ViewBuilder from '@/components/schema/ViewBuilder.vue';

import { UPDATE } from '@/constants/actions';
import { SERVICES as SERVICES_SUBJECT } from '@/constants/subjects';
import { SERVICES_ARCHIVE, SERVICES, SERVICES_EDIT } from '@/constants/routes';
import analyticsService from '@/services/analytics';
import { ARCHIVE_SERVICE, UNARCHIVE_SERVICE, DETAILED_SERVICE } from '@/constants/analyticsActions';
import servicesService from '@/services/services';
import { createModelData } from '@/schemas/createModelData';

import { schema } from '@/schemas/service.view.schema';

export default {
  name: 'ServiceDetailed',
  components: {
    EntityHeader,
    ViewBuilder,
  },
  props: {
    id: { type: Number, required: true },
    prevPage: { type: Number, default: 0 },
  },
  data() {
    return {
      service: createModelData(schema),
      isLoading: false,
      modal: {
        show: false,
        message: '',
        info: true,
      },
    };
  },
  computed: {
    canUpdate() {
      return this.$can(UPDATE, SERVICES_SUBJECT);
    },
    isArchived() {
      return this.service.isArchived;
    },
    backRoute() {
      const routeName = this.isArchived ? SERVICES_ARCHIVE : SERVICES;

      return { name: routeName, query: { page: this.prevPage || 1 } };
    },
    editRoute() {
      return { name: SERVICES_EDIT, params: { id: this.id } };
    },
  },
  mounted() {
    analyticsService.track(DETAILED_SERVICE);
    this.initData();
  },
  methods: {
    async initData() {
      this.isLoading = true;
      try {
        const service = await servicesService.getServicesById(this.id);
        const imageForList = [{ id: 1, selected: true, url: service.imageForList }];
        const imageForRetrieve = [{ id: 1, selected: true, url: service.imageForRetrieve }];
        const contractor = service.contractor?.name;
        const category = service.category?.name;

        this.service = {
          ...service,
          imageForList,
          imageForRetrieve,
          contractor,
          category,
        };
      } finally {
        this.isLoading = false;
      }
    },
    archive() {
      const dispatch = this.isArchived ? this.unarchiveServices : this.archiveServices;
      dispatch();
    },
    archiveServices() {
      analyticsService.track(ARCHIVE_SERVICE);
      const options = { isArchived: true, services: [this.id] };
      this.isLoading = true;
      servicesService
        .archiveServices(options)
        .then(() => {
          this.initData();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    unarchiveServices() {
      analyticsService.track(UNARCHIVE_SERVICE);
      const options = { isArchived: false, services: [this.id] };
      this.isLoading = true;
      servicesService
        .archiveServices(options)
        .then(() => {
          this.initData();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    closeModal() {
      this.modal.show = false;
      this.modal.message = '';
      this.modal.info = false;
    },
  },
  schema,
};
</script>

import { LIST } from '@/constants/viewTypes';
import * as schemaItemTypes from './schemaItemTypes';
import { rules } from './validationRules';

/**
 * @type {Array<import('./schema').default>}
 */
export const schema = [
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'label.information',
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.COAST,
            label: 'label.initial_cost',
            prop: 'initialAmount',
            rules: [rules.REQUIRED],
          },
          {
            type: schemaItemTypes.STRING,
            label: 'label.contractor',
            prop: 'contractor',
            rules: [rules.REQUIRED],
          },
          {
            type: schemaItemTypes.FILE_LIST,
            prop: 'imageForList',
            rules: [rules.REQUIRED, rules.ALL_LOADED],
            label: '',
          },
          {
            type: schemaItemTypes.FILE_LIST,
            prop: 'imageForRetrieve',
            rules: [rules.REQUIRED, rules.ALL_LOADED],
            label: '',
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.STRING,
            label: 'label.short_description',
            prop: 'shortDescription',
            rules: [rules.REQUIRED],
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.STRING,
            label: 'label.category',
            prop: 'category',
            rules: [rules.REQUIRED],
          },
          {
            type: schemaItemTypes.BOOLEAN,
            label: 'label.public_access',
            prop: 'publicAccess',
            rules: [rules.REQUIRED],
          },
          {
            type: schemaItemTypes.NUMBER,
            label: 'label.order',
            prop: 'order',
            rules: [rules.REQUIRED],
          },
          {},
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.STRING,
            label: 'label.description',
            prop: 'description',
            rules: [rules.REQUIRED],
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.EXPANSION_PANEL,
            label: 'label.projects',
            prop: 'projectsNames',
            rules: [rules.MANY_REQUIRED],
            showCount: true,
          },
        ],
      },
    ],
  },

  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'services.service_scenarios',
    children: [
      {
        type: schemaItemTypes.LIST,
        prop: 'scripts',
        uniqueKey: 'id',

        subTitle: 'label.scenario',
        viewType: LIST.counter,
        children: [
          {
            type: schemaItemTypes.SCRIPT_ITEM,
          },
        ],
      },
    ],
  },
];

export { schema as default };
